.form-group {
  margin-bottom: 1rem;
}

.btn-info {
  color: #fff;
  background-color: #b30000;
  border-color: #b30000;
}

.btn-info {
  --bs-btn-color: #fff !important;
  --bs-btn-bg: #b30000 !important;
  --bs-btn-border-color: #b30000 !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: #a40000 !important;
  --bs-btn-hover-border-color: #a40000 !important;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: #a40000 !important;
  --bs-btn-active-border-color: #a40000 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff !important;
  --bs-btn-disabled-bg: #b30000 !important;
  --bs-btn-disabled-border-color: #b30000 !important;
}

.btn-outline-info:hover {
  background-color: #a40000 !important;
  color: #fff !important;
}

.btn-close:focus {
  box-shadow: 0 0 0 0.25rem #fff !important;
}

.btn-close:hover {
  box-shadow: 0 0 0 0.25rem #ffd4d4 !important;
}

.btn:focus {
  background-color: #c20000 !important;
  border-color: #c20000 !important;
  color: #fff !important;
}

.btn:hover {
  box-shadow: 0 2px 7px #00000047 !important;
  cursor: pointer !important;
}

.btn-outline-info:hover {
  color: #fff !important;
  background-color: #b30000 !important;
  border-color: #b30000 !important;
}
.btn:hover {
  text-decoration: none !important;
}

.btn-info:hover {
  background-color: #c20000 !important;
  border-color: #c20000 !important;
  color: #fff !important;
}
.btn-info:active {
  background-color: #c20000 !important;
  border-color: #c20000;
  color: #fff;
}


  /* Mantener el mismo color del botón en focus-visible */
  .btn:focus-visible {
    background-color: #c20000 !important;
    border-color: #c20000 !important;
    color: #fff !important;
    box-shadow: none;
  }

  /* Para botones específicos */
  .btn-primary:focus-visible {
    background-color: #0356ad !important;
    border-color: #0356ad !important;
    color: #fff !important;
  }

  .btn-info:focus-visible {
    background-color: #c20000 !important;
    border-color: #c20000 !important;
    color: #fff !important;
  }

  /* Si quieres quitar el outline por defecto */
  .btn:focus {
    outline: none !important;
    box-shadow: none !important;
  }


.success {
  color: #40c057 !important;
}

.danger {
  color: #b30000 !important;
}
.info {
  color: #b30000 !important;
}
.primary {
  color: #b30000 !important;
}

.btn-outline-info {
  border: 1px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
  border-color: #b30000 !important;
  background-color: transparent !important;
  color: #b30000 !important;
}


a:not([href]) {
  color:inherit;
  text-decoration:none
}

.navbar {
  z-index: 1;
}

.card {
  margin: 15px 0;
  box-shadow: -8px 8px 14px #192a461c;
}

.white {
  color: #fff !important;
}

.underline{
  text-decoration: underline !important;
  color: #b30000;
}

a:hover{
  color: #c20000;
}

a:focus
{
  color: #c20000;
  text-decoration: none;
}

.center-elements{
    text-align: center;
  }

  .text-decoration-underline{
    text-decoration: underline !important;
  }
  
  .navbar{
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1.5rem;
    top: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0 6px 0 0 rgba(0,0,0,.01),0 15px 32px 0 rgba(0,0,0,.06);
  }
  
  .swal2-styled.swal2-confirm{
    background-color: #b30000 !important;
  }
  
  .main-panel {
    margin-top: 65px;
  }

  .modal-footer{
    border-top: none !important;
  }

  
  .ModalClass-xl .modal-dialog {
    max-width: 98% !important;
  }
  .ModalClass-lg .modal-dialog {
    max-width: 65% !important;
  }

  .ModalClass-sm .modal-dialog {
    max-width: 45% !important;
  }

  @media ( max-width: 900px ){
    .ModalClass-lg .modal-dialog {
      max-width: 98% !important;
    }

    .ModalClass-sm .modal-dialog {
      max-width: 98% !important;
    }
  }

  